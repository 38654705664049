<template>
	<div>
		<div class="table">
			<div class="info">
				<span>记录明细</span>
				<el-button type="primary" @click="dialogVisible = true">职工应在校时间设置</el-button>
			</div>
			<div class="search">
				<span>时间：</span>
				<el-date-picker class="time" size="medium" type="daterange" v-model="picker" start-placeholder="开始日期"
					range-separator="-" end-placeholder="结束日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd"
					unlink-panels @change="pickerChange">
				</el-date-picker>
			</div>
			<el-table :data="staffData" v-loading="loading">
				<el-table-column type="index" label="序号" align="center"></el-table-column>
				<el-table-column prop="date" label="日期" align="center"></el-table-column>
				<el-table-column prop="teacherNum" label="教师人数" align="center"></el-table-column>
				<el-table-column prop="authTeacherNum" label="认证人数" align="center"></el-table-column>
				<el-table-column prop="arriveNum" label="实际到校人数" align="center"></el-table-column>
				<el-table-column prop="lateNum" label="迟到人数" align="center"></el-table-column>
				<el-table-column prop="leaveNum" label="早退人数" align="center"></el-table-column>
				<el-table-column prop="" label="查看详情" align="center">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="detail(scope.row.date)">详情</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div>
				<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
					layout="total, prev, pager, next, jumper" :current-page="currpage" :total="CountNum"
					style="margin-top:20px;text-align: center;">
				</el-pagination>
			</div>
		</div>
		<el-dialog title="在校时间设置" center :visible.sync="dialogVisible" width="500px" :close-on-click-modal="false">
			<div style="text-align: center;">
				<el-time-select v-model="start" placeholder="开始时间"
					:picker-options="{start: '06:00',step: '00:10',end: '20:00'}" style="width: 120px;">
				</el-time-select>
				<span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>
				<el-time-select v-model="end" placeholder="结束时间"
					:picker-options="{start: '06:00',step: '00:10',end: '20:00'}" style="width: 120px;">
				</el-time-select>
			</div>
			<div slot="footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="ok">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		staffWorkTime,
		arriveStaffList
	} from '@/api/user.js'
	export default {
		data() {
			return {
				staffData: [], //教职工出入数据
				picker: null, //选择的时间
				start: '', //在校开始时间
				end: '', //在校结束时间
				loading: false, //加载框
				dialogVisible: false, //在校时间设置弹框
				pagesize: 10, // 每页显示条目个数
				currpage: 1, // 初始页
				CountNum: 0, // 总条数
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近半个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}]
				}
			}
		},
		created() {
			this.getStaffData(1); // 教职工出入列表
		},
		methods: {
			async getStaffData(currpage, selectPicker) { // 教职工出入列表
				this.loading = true;
				const data = {
					page: currpage, //	当前页数
					limit: this.pagesize, // 每页条数
					startDate: !!selectPicker ? selectPicker[0] : '', //开始时间
					endDate: !!selectPicker ? selectPicker[1] : '' //结束时间
				}
				let res = await arriveStaffList(data); //请求接口
				if (res.status.code == 1) {
					this.CountNum = parseInt(res.data.countNum); // 总条数
					this.staffData = res.data.list; // 教职工出入列表数据
					this.currpage = currpage; //分页显示当前页
				} else {
					this.$message.error(res.status.msg);
				}
				this.loading = false;
			},
			async ok() {
				if (!this.start || !this.end) {
					this.$message.warning("请完善在校离校时间");
				} else if (this.start >= this.end) {
					this.$message.warning("在校的结束时间要大于开始时间");
				} else {
					const data = { //请求数据
						startTime: this.start.replace(/^0/, '').replace(/:/, ''),
						endTime: this.end.replace(/^0/, '').replace(/:/, '')
					};
					let res = await staffWorkTime(data); //请求接口
					if (res.status.code == 1) {
						this.$message.success("设置成功");
						this.dialogVisible = false;
					} else {
						this.$message.error(res.status.msg);
					}
				}
			},
			pickerChange(e) { //选择日期
				this.getStaffData(1, e); // 根据选取的日期获取教职工出入列表数据
			},
			detail(date) { //跳去教职工出入信息
				this.$router.push({
					name: 'StaffHistory',
					params: {
						date: date
					}
				})
			},
			handleCurrentChange(val) { // 分页
				this.getStaffData(val);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.table {
		padding: 20px 50px;

		.info {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 20px;
			font-weight: bold;
			line-height: 50px;
		}

		.search {
			display: flex;
			align-items: center;

			.time {
				margin-left: 20px;
				width: 300px;
			}
		}
	}
</style>
